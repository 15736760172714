<template>
  <!-- 资料库>新增资料 -->
    <div class="dicts"  v-loading.fullscreen.lock="loadingDetail">
        <div class="form-header-btn">
            <el-page-header
            @back="goBack"
            content="运营模板/查看"
            >
                <template slot="title">
                    <i class="el-icon-arrow-left"></i>返回
                </template>
            </el-page-header>
        </div>
        <el-radio-group v-model="tabPosition" @change="changeTab" style="margin-bottom: 1.5625vw;">
            <el-radio-button label="1">基础设置</el-radio-button>
            <el-radio-button label="2">项目设置</el-radio-button>
        </el-radio-group>

        <el-form ref="ruleForm" v-if="tabPosition == 1"  style="margin-top:1.5625vw;width: 80%;" :model="form" :label-width="labelWidth">
            <el-form-item label="模板名称：" prop="templateName">
                <el-input disabled v-model="form.templateName" placeholder="请输入模板名称"></el-input>
            </el-form-item>
            <el-form-item label="描述：" prop="templateDesc">
                <el-input disabled type="textarea"  :rows="4"  v-model="form.templateDesc" placeholder="请输入描述"></el-input>
            </el-form-item>
            <el-form-item label="排序：">
                <el-input disabled v-model="form.templateSort" placeholder="请输入排序"></el-input>
            </el-form-item>
            <el-form-item label="是否启用：" prop="isUse">
                <el-radio-group disabled  v-model="form.isUse">
                    <el-radio :label="1">启用</el-radio>
                    <el-radio :label="0">不启用</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div v-else>
            <div class="setLevel">
                <el-radio-group v-model="openLevel" @change="changeOpenLevel">
                <el-radio :label="0">展开一级</el-radio>
                <el-radio :label="2">展开二级</el-radio>
                <el-radio :label="3">展开全部</el-radio>
                </el-radio-group>
            </div>
            <el-table
                :header-cell-style="{
                    background: '#F1F4F9',
                    color: '#5E6D9B',
                    fontWeight: '400',
                    'text-align':'center'
                }"
                :data="form.itemTemplateTempVoList"
                style="width: 100%; margin-bottom: 1.04167vw;margin-top: 1.04167vw;min-height:calc(100% - 5.52083vw);overflow-y: scroll;overflow:visible;"
                ref="tableDatas" 
                :tree-props="{ children: 'templateTempVoList', hasChildren: 'hasChildren' }"
                :row-key="row => { return row.itemId }"
                :expand-row-keys="expandRow"
                show-summary
                :summary-method="getSummaries"
            >
                <el-table-column  prop="itemName" width="200" label="运营项目" align="left" >
                </el-table-column>
                <el-table-column  prop="predictDay" width="220" label="预计办理时长（天）" align="center" >
                </el-table-column>
                <el-table-column  prop="timeoutUserType" width="160" label="逾期反馈人" align="center" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.timeoutUserType">{{scope.row.timeoutUserType.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column  prop="jobId" label="岗位" align="center" >
                </el-table-column>
                <el-table-column  prop="isMust" label="完成情况" align="center" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.isMust">{{scope.row.isMust.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column  prop="startTime"  width="200"  align="center" >
                    <template slot="header" >
                        <span class="redColor">*</span>开始时间
                    </template>
                </el-table-column>
                <el-table-column  prop="endTime"  width="200" label="截至时间" align="center" >
                </el-table-column>
                <el-table-column  prop=""  width="150" label="频次" align="center" >
                    <template slot="header" >
                        <span class="redColor" >*</span>频次
                    </template>
                    <template slot-scope="scope">
                        <span v-if="scope.row.level == 3" >
                            {{scope.row.itemTimes}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column  prop="tempPeriod"  width="150" label="周期(天)" align="center" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.level == 3" >
                            {{scope.row.tempPeriod}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column  prop="updateTime" width="150" label="更新时间" align="center" >
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="250">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.level == 3" @click="handleEdtail(scope.row)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-drawer
        title="运营项目详情"
        :append-to-body="true"
        :destroy-on-close="true"
        :visible.sync="drawerVisible"
        size="50%"
        :before-close="handleDrawerClose">
            <div class="drawerBox">
                <item-detail :detailId="detailId" v-if="drawerVisible"></item-detail>
            </div>
        </el-drawer>
    </div>
</template>
<script>
import itemDetail from '@/views/opratingManage/operationalItems/edit.vue'
export default {
    components:{
        itemDetail
    },
    data(){
        return{
            openLevel:3,
            form:{
            },
            tabPosition:'1',
            expandRow:[],
            drawerVisible:false,
            detailId:'',
            loadingDetail:false,

            labelWidth: '120px',
        }
    },
    created(){
        this.getDetail()
    },
    mounted(){
        window.addEventListener("resize",(e) => {
        if(e.target.innerWidth < 1300){
            this.labelWidth = '80px'
        } else {
            this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
        }
        this.$forceUpdate()
        })
        if(window.innerWidth < 1300){
        this.labelWidth = '80px'
        } else {
        this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
        }
    },
    methods:{
        changeTab(val){
            if(val == 2){
                setTimeout(()=>{
                    this.changeOpenLevel(4)
                },300)
            }
        },
        goBack() {
            this.$router.go(-1);
        },
        handleDrawerClose(done){
            this.detailId = ''
            this.drawerVisible = false
        },
        // 跳转详情
        handleEdtail(row){
            this.drawerVisible = true
            this.detailId = row.itemId
        },
        // 展开层级
        changeOpenLevel(val){
            this.resetTabDate();
            if(val == 4){
                this.expandRow = []
                if(this.form.itemTemplateTempVoList){
                    this.form.itemTemplateTempVoList.forEach(e=>{
                        this.expandRow.push(e.itemId)
                        this.$refs.tableDatas.toggleRowExpansion(e, true);
                        if(e.templateTempVoList){
                            e.templateTempVoList.forEach(el=>{
                                this.expandRow.push(el.itemId)
                                this.$refs.tableDatas.toggleRowExpansion(el, true);
                                if(el.templateTempVoList){
                                    el.templateTempVoList.forEach(ele=>{
                                        this.$refs.tableDatas.toggleRowExpansion(ele, true);
                                        this.expandRow.push(ele.itemId)
                                    })
                                }
                            })
                        }
                    })
                }
            }else  if(val == 3){
                this.expandRow = []
                this.form.itemTemplateTempVoList.map((item) => {
                this.expandRow.push(item.itemId);
                this.$refs.tableDatas.toggleRowExpansion(item, true);
                if (item.templateTempVoList && item.templateTempVoList.length > 0) {
                    item.templateTempVoList.map((itemChild) => {
                        this.expandRow.push(itemChild.itemId);
                        this.$refs.tableDatas.toggleRowExpansion(itemChild, true);
                    });
                }
                });
            }else if(val == 2){
                this.expandRow = []
                this.form.itemTemplateTempVoList.map((item) => {
                    this.expandRow.push(item.itemId);
                    this.$refs.tableDatas.toggleRowExpansion(item, true)
                });
            }
        },
        //收起展开菜单
        resetTabDate(arr) {
            this.expandRow = [];
            let data = arr || this.form.itemTemplateTempVoList;
            if(data){
                data.forEach((item) => {
                    this.$refs.tableDatas.toggleRowExpansion(item, false);
                    if (
                    Object.prototype.toString.call(item.templateTempVoList) ==
                    "[object Array]"
                    ) {
                    this.resetTabDate(item.templateTempVoList);
                    }
                });
            }
        },
        getDetail(){
            this.loadingDetail = true
            this.$api.workTemplateDetail(this.$route.query.id).then(res=>{
                this.loadingDetail = false
                if(res.data.code == '0'){
                    res.data.data.isUse =  Number(res.data.data.isUse.value)
                    this.form = res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        //表尾合计
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if ( index === 0) {
                    sums[index] = "总计时长";
                    return;
                }
                if (index === 1) {
                    sums[index] = this.form.totalPredictDay;
                    return;
                }
            });
            return sums;
        },
    },
    beforeDestroy(){
        window.removeEventListener("resize",(e) => {
        if(e.target.innerWidth < 1300){
            this.labelWidth = '80px'
        } else {
            this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
        }
        this.$forceUpdate()
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dicts {
    background-color: #ffffff;
    height: calc(100% - 64px);
    padding: 34px 30px 30px 30px;
    .tableList {
        height: calc(100% - 62px);
    }
    .setLevel{
        display: flex;
        justify-content: flex-end;
        margin-top:20px
    }
}
.drawerBox{
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}
.dict{
    height: 100% ;
    min-height: 100%;
    overflow-y: scroll;
    padding: 0 0 0 20px;
}
.redColor{
    color: red;
}
/deep/.el-table__footer-wrapper tbody td, .el-table__header-wrapper tbody td{
    color: red;
    font-weight: bold;
} 
</style>